@import 'common/search.less';

[data-platform='android'] {
    @import 'android/app-bar.less';
    @import 'android/checkbox.less';
    @import 'android/radio.less';
    @import 'android/switch.less';

    tui-badge-notification[data-size='l'] {
        --t-size: 1.375rem;
    }
}

[data-platform='ios'] {
    @import 'ios/app-bar.less';
    @import 'ios/checkbox.less';
    @import 'ios/switch.less';

    tui-segmented > *:not(tui-segmented_active):active {
        background-color: var(--tui-background-neutral-1);
    }

    tui-segmented > .tui-segmented_active:active {
        opacity: var(--tui-disabled-opacity);
    }
}

[data-platform='android'],
[data-platform='ios'] {
    @import 'common/badge.less';
    @import 'common/badge-notification.less';
    @import 'common/block-status.less';
    @import 'common/button.less';
    @import 'common/title.less';
    @import 'common/card-large.less';
    @import 'common/chip.less';
    @import 'common/segmented.less';
    @import 'common/header.less';
    @import 'common/message.less';
}
