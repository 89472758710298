@import '@taiga-ui/core/styles/taiga-ui-local.less';

input[tuiSwitch] {
    block-size: 1.9375rem;
    inline-size: 3.1875rem;
    border-radius: 2rem;
    color: #fff;
    background: var(--tui-background-neutral-2);
    overflow: visible;

    &::before {
        opacity: 0;
    }

    &::after {
        .transition(~'transform, width');

        right: auto;
        inline-size: 1.9375rem;
        block-size: 1.9375rem;
        border-radius: 2rem;
        background: currentColor;
        transform: scale(0.871);
        outline: none;
        box-shadow:
            0 0.25rem 0.125rem rgba(0, 0, 0, 0.06),
            0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
    }

    &:active::after {
        inline-size: 2.3rem;
    }

    &:checked,
    &:checked:hover,
    &:checked:active {
        background: var(--tui-background-accent-2);

        &:invalid {
            background: var(--tui-status-negative);
        }

        &::after {
            transform: scale(0.871) translateX(1.44rem);
        }

        &:active::after {
            transform: scale(0.871) translateX(1.0775rem);
        }
    }
}
