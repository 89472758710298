[tuiCardLarge] [tuiButton] {
    inline-size: 100%;
}

[tuiButton],
[tuiIconButton] {
    &[data-size='xs'],
    &[data-size='s'],
    &[data-size='l'] {
        --t-radius: 1rem;
    }

    &[data-size='m'] {
        --t-radius: 0.75rem;
    }

    &[data-size='l'] {
        font: var(--tui-font-text-l);
        font-weight: bold;
    }

    &[data-appearance^='primary'],
    &[data-appearance^='secondary'],
    &[data-appearance^='accent'],
    &[data-appearance^='destructive'],
    &[data-appearance^='opposite'],
    &[data-appearance^='flat'] {
        &:disabled:not(._loading),
        &[data-state='disabled']:not(._loading) {
            background: var(--tui-background-neutral-1);
            color: var(--tui-text-tertiary);
            opacity: 1;

            &[data-appearance^='flat'] {
                background: transparent;
            }
        }
    }
}

[tuiButton][tuiButtonVertical] {
    gap: 0.5rem;
}
