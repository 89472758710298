[tuiCardLarge][data-space] {
    &[data-space='normal'] {
        --t-padding: 1.25rem;
        --t-space: 1.25rem;
    }

    &[data-space='compact'] {
        --t-padding: 1rem;
        --t-space: 1rem;
        --t-comp: -0.125rem;
    }
}
