@import '@taiga-ui/core/styles/taiga-ui-local.less';

input[type='search'][tuiSearch] {
    .transition(background);

    block-size: 2.25rem;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 0.625rem;
    padding: 0 0 0 1.875rem;
    font:
        1.0625rem 'SF Pro Text',
        sans-serif;
    color: var(--tui-text-primary);
    caret-color: var(--tui-text-action);
    background: var(--tui-background-neutral-2)
        url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M4%2010.4714C4%206.90319%206.93142%204%2010.5343%204C14.1372%204%2017.0686%206.90319%2017.0686%2010.4714C17.0686%2011.9473%2016.5691%2013.3016%2015.7257%2014.3882L19.7298%2018.3781C19.9017%2018.5484%2020%2018.7836%2020%2019.0431C20%2019.5864%2019.6151%2020%2019.0583%2020C18.7963%2020%2018.5507%2019.9108%2018.3623%2019.7243L14.3337%2015.7263C13.261%2016.4886%2011.9591%2016.9427%2010.5343%2016.9427C6.93142%2016.9427%204%2014.0395%204%2010.4714ZM5.4002%2010.4714C5.4002%2013.2772%207.70113%2015.556%2010.5343%2015.556C13.3675%2015.556%2015.6684%2013.2772%2015.6684%2010.4714C15.6684%207.66548%2013.3675%205.38672%2010.5343%205.38672C7.70113%205.38672%205.4002%207.66548%205.4002%2010.4714Z%22%20fill%3D%22%239299A2%22%2F%3E%3C%2Fsvg%3E')
        no-repeat 0.25rem center;

    &::placeholder {
        color: var(--tui-text-secondary);
    }

    &:active {
        background-color: var(--tui-background-neutral-2-pressed);
    }

    &::-webkit-search-cancel-button {
        .transition(background);

        display: block;
        appearance: none;
        block-size: 2.25rem;
        inline-size: 2rem;
        box-sizing: border-box;
        border: 0.5rem solid transparent;
        margin: 0;
        background: var(--tui-text-secondary);
        opacity: 1;
        mask: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%2016C3.63137%2016%200%2012.3686%200%208C0%203.62353%203.62353%200%207.99216%200C12.3686%200%2016%203.62353%2016%208C16%2012.3686%2012.3765%2016%208%2016ZM5.36471%2011.2941C5.5451%2011.2941%205.7098%2011.2314%205.83529%2011.1059L8%208.92549L10.1725%2011.1059C10.2902%2011.2314%2010.4549%2011.2941%2010.6353%2011.2941C11.0039%2011.2941%2011.2941%2011.0039%2011.2941%2010.6431C11.2941%2010.4627%2011.2314%2010.298%2011.098%2010.1804L8.92549%208.00784L11.1059%205.82745C11.2471%205.68627%2011.302%205.5451%2011.302%205.36471C11.302%205.00392%2011.0118%204.72157%2010.651%204.72157C10.4784%204.72157%2010.3373%204.77647%2010.2039%204.9098L8%207.09804L5.81176%204.91765C5.68627%204.8%205.5451%204.73726%205.36471%204.73726C5.00392%204.73726%204.72157%205.01177%204.72157%205.38039C4.72157%205.55294%204.78431%205.7098%204.9098%205.83529L7.08235%208.00784L4.9098%2010.1882C4.78431%2010.3059%204.72157%2010.4706%204.72157%2010.6431C4.72157%2011.0039%205.00392%2011.2941%205.36471%2011.2941Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E')
            no-repeat center;
    }

    &::-webkit-search-cancel-button:active {
        background: var(--tui-text-secondary-pressed);
    }
}
