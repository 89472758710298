tui-segmented[data-size] {
    --t-padding: 0.625rem;
    --t-gap: 0.625rem;
    --t-margin: -0.25rem;
    --t-height: var(--tui-height-s);

    font: var(--tui-font-text-s);
    border-radius: 0.625rem;

    tui-icon {
        font-size: 1rem;
    }
}
