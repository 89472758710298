@import 'tailwindcss';



@import '@angular/cdk/overlay-prebuilt.css';

@import url('./styles/reset.css') layer(base);

@import './libs/ui/hlm-tailwind-preset.css';

/*
  The default border color has changed to `currentColor` in Tailwind CSS v4,
  so we've added these compatibility styles to make sure everything still
  looks the same as it did with Tailwind CSS v3.

  If we ever want to remove these styles, we need to add an explicit border
  color utility to any element that depends on these defaults.
*/
@layer base {
  *,
  ::after,
  ::before,
  ::backdrop,
  ::file-selector-button {
    border-color: var(--color-gray-200, currentColor);
  }
}

@utility content-auto {
  content-visibility: auto;

}

@utility form-control {
  @apply w-full rounded-md border bg-white border-gray-300 p-2 focus:outline-hidden focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-pointer disabled:bg-gray-100
}

@utility form-label {
  @apply block font-medium text-gray-700 mb-1
}


@custom-variant ng-invalid (&.ng-invalid.ng-touched);

@custom-variant segment-active (&.tui-segmented_active);


:root {
  --font-sans: '';
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;
  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: 269 92% 70%; /* Updated to Tailwind's purple-500 */
  --primary-foreground: 0 0% 98%; /* Light text for contrast */
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;
  --ring: 269 92% 70%; /* Updated to match primary */
  --radius: 0.5rem;
  color-scheme: light;
}


tui-dialog[data-appearance='template-modal'] {

  @apply border-none rounded-md

}

tui-dialog[data-appearance='fullscreen'][data-size='fullscreen'] .t-content
{
  @apply !px-4 !py-2 flex flex-col grow-1
}

tui-dialog[data-appearance='fullscreen'][data-size='fullscreen'] .t-content>section
{
  @apply flex flex-col grow-1
}

tui-dialog[data-appearance='fullscreen']
{
  height: 100dvh;
  width: 100dvw;
}

tui-dialog[data-appearance='fullscreen'][data-size='fullscreen'] .t-filler
{
  flex-grow: unset;
}

tui-input-date [tuiAppearance][data-appearance=textfield] {
  box-shadow: unset !important;
}

tui-dialog[data-appearance='template-modal'] .t-content {
  background-color: #f1f1f1f1;
  -webkit-backdrop-filter: blur(23px);
  backdrop-filter: blur(23px);

}

[tuiappearance][data-appearance="custom-select"] {
  @apply w-full rounded-md border border-gray-300 p-2 focus:outline-hidden focus:ring-2 focus:ring-blue-500  ng-invalid:border-red-500 ng-invalid:bg-red-50;
}


.required::after {
  content: '*';
  @apply text-red-500 ml-1;
}







tui-drawer.drawer {
  @apply top-0 rounded-none;
}


.carousel fieldset.t-item
{
  justify-content: unset;
}


tui-drawer._overlay .t-content {
  block-size: 100%;
}

html, body {
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}