tui-badge,
[tuiBadge] {
    font-weight: bold;

    &[data-size='s'] {
        font: var(--tui-font-text-xs);
        font-weight: bold;
    }

    &[data-size='xl'] {
        --t-padding: 0 0.625rem;
        --t-size: 1.75rem;

        font: var(--tui-font-text-s);
        font-weight: bold;
    }
}
