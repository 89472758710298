@font-fallback: -apple-system, 'BlinkMacSystemFont', system-ui, 'Roboto', 'Segoe UI', 'Helvetica Neue', sans-serif;

&:root {
    // Fonts
    --tui-font-heading: 'Manrope', @font-fallback;
    --tui-font-text: 'Manrope', @font-fallback;
    --tui-font-offset: ~'0rem';
    // Heading
    --tui-font-heading-1: bold calc(var(--tui-font-offset) + 3.125rem) / 3.5rem var(--tui-font-heading);
    --tui-font-heading-2: bold calc(var(--tui-font-offset) + 2.75rem) / 3rem var(--tui-font-heading);
    --tui-font-heading-3: bold calc(var(--tui-font-offset) + 2.25rem) / 2.5rem var(--tui-font-heading);
    --tui-font-heading-4: bold calc(var(--tui-font-offset) + 1.75rem) / 2rem var(--tui-font-heading);
    --tui-font-heading-5: bold calc(var(--tui-font-offset) + 1.5rem) / 1.75rem var(--tui-font-heading);
    --tui-font-heading-6: bold calc(var(--tui-font-offset) + 1.25rem) / 1.5rem var(--tui-font-heading);
    // Body
    --tui-font-text-xl: normal calc(var(--tui-font-offset) + 1.1875rem) / 1.75rem var(--tui-font-text);
    --tui-font-text-l: normal calc(var(--tui-font-offset) + 1.0625rem) / 1.75rem var(--tui-font-text);
    --tui-font-text-m: normal calc(var(--tui-font-offset) + 1rem) / 1.5rem var(--tui-font-text);
    --tui-font-text-s: normal calc(var(--tui-font-offset) + 0.8125rem) / 1.25rem var(--tui-font-text);
    --tui-font-text-xs: normal calc(var(--tui-font-offset) + 0.6875rem) / 1rem var(--tui-font-text);
    // Reduced
    --tui-font-text-ui-l: normal calc(var(--tui-font-offset) + 1.0625rem) / 1.5rem var(--tui-font-text);
    --tui-font-text-ui-m: normal calc(var(--tui-font-offset) + 1rem) / 1.25rem var(--tui-font-text);
    --tui-font-text-ui-s: normal calc(var(--tui-font-offset) + 0.8125rem) / 1rem var(--tui-font-text);
    --tui-font-text-ui-xs: normal calc(var(--tui-font-offset) + 0.6875rem) / 0.8125rem var(--tui-font-text);
    // Radii
    --tui-radius-xs: 0.25rem;
    --tui-radius-s: 0.5rem;
    --tui-radius-m: 0.625rem;
    --tui-radius-l: 0.75rem;
    --tui-radius-xl: 1.5rem;
    // Sizes
    --tui-height-xs: 1.5rem;
    --tui-height-s: 2rem;
    --tui-height-m: 2.75rem;
    --tui-height-l: 3.5rem;
    // Input padding
    --tui-padding-s: 0.625rem;
    --tui-padding-m: 0.75rem;
    --tui-padding-l: 1rem;
    // Misc
    --tui-duration: 0.3s;
    --tui-disabled-opacity: 0.56;
}
