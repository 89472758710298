.dark() {
    // Backgrounds
    --tui-background-base: #222;
    --tui-background-base-alt: #333;
    --tui-background-neutral-1: rgba(255, 255, 255, 0.08);
    --tui-background-neutral-1-hover: rgba(255, 255, 255, 0.16);
    --tui-background-neutral-1-pressed: rgba(255, 255, 255, 0.24);
    --tui-background-neutral-2: rgba(255, 255, 255, 0.24);
    --tui-background-neutral-2-hover: rgba(255, 255, 255, 0.32);
    --tui-background-neutral-2-pressed: rgba(255, 255, 255, 0.4);
    --tui-background-accent-opposite: #fff;
    --tui-background-accent-opposite-hover: #f6f6f6;
    --tui-background-accent-opposite-pressed: #ededed;
    --tui-background-elevation-1: #292929;
    --tui-background-elevation-2: #333;
    --tui-background-elevation-3: #333;
    // Other
    --tui-service-autofill-background: rgb(85, 74, 42);
    --tui-border-normal: rgba(255, 255, 255, 0.14);
    --tui-border-hover: rgba(255, 255, 255, 0.6);
    --tui-border-focus: rgba(255, 255, 255, 0.64);
    // Statuses
    --tui-status-negative: rgba(255, 140, 103, 1);
    --tui-status-negative-pale: rgba(244, 87, 37, 0.32);
    --tui-status-negative-pale-hover: rgba(244, 87, 37, 0.4);
    --tui-status-positive: rgb(74, 201, 155);
    --tui-status-positive-pale: rgba(74, 201, 155, 0.32);
    --tui-status-positive-pale-hover: rgba(74, 201, 155, 0.4);
    --tui-status-warning: rgb(255, 199, 0);
    --tui-status-warning-pale: rgba(255, 199, 0, 0.32);
    --tui-status-warning-pale-hover: rgba(255, 199, 0, 0.4);
    --tui-status-info: rgb(112, 182, 246);
    --tui-status-info-pale: rgba(112, 182, 246, 0.32);
    --tui-status-info-pale-hover: rgba(112, 182, 246, 0.4);
    --tui-status-neutral: rgb(149, 155, 164);
    // Text
    --tui-text-primary: rgba(255, 255, 255, 1);
    --tui-text-secondary: rgba(255, 255, 255, 0.72);
    --tui-text-tertiary: rgba(255, 255, 255, 0.6);
    --tui-text-action: #6788ff;
    --tui-text-action-hover: #526ed3;
    --tui-text-positive: #44c596;
    --tui-text-positive-hover: #3aa981;
    --tui-text-negative: #ff8c67;
    --tui-text-negative-hover: #bb593a;
}

.light() {
    // Backgrounds
    --tui-background-base: #fff;
    --tui-background-base-alt: #f6f6f6;
    --tui-background-neutral-1: rgba(0, 0, 0, 0.04);
    --tui-background-neutral-1-hover: rgba(0, 0, 0, 0.08);
    --tui-background-neutral-1-pressed: rgba(0, 0, 0, 0.12);
    --tui-background-neutral-2: rgba(0, 0, 0, 0.08);
    --tui-background-neutral-2-hover: rgba(0, 0, 0, 0.1);
    --tui-background-neutral-2-pressed: rgba(0, 0, 0, 0.14);
    --tui-background-accent-1: #526ed3;
    --tui-background-accent-1-hover: #6c86e2;
    --tui-background-accent-1-pressed: #314692;
    --tui-background-accent-2: #ff7043;
    --tui-background-accent-2-hover: #ff9a94;
    --tui-background-accent-2-pressed: #e7716a;
    --tui-background-accent-opposite: #000;
    --tui-background-accent-opposite-hover: #333;
    --tui-background-accent-opposite-pressed: #808080;
    --tui-background-elevation-1: #fff;
    --tui-background-elevation-2: #fff;
    --tui-background-elevation-3: #fff;
    // Other
    --tui-service-autofill-background: #fff5c0;
    --tui-service-selection-background: rgba(112, 182, 246, 0.12);
    --tui-service-backdrop: rgba(0, 0, 0, 0.75);
    --tui-border-normal: rgba(0, 0, 0, 0.1);
    --tui-border-hover: rgba(0, 0, 0, 0.16);
    --tui-border-focus: rgba(51, 51, 51, 0.64);
    // Statuses
    --tui-status-negative: rgba(244, 87, 37, 1);
    --tui-status-negative-pale: rgba(244, 87, 37, 0.12);
    --tui-status-negative-pale-hover: rgba(244, 87, 37, 0.24);
    --tui-status-positive: rgba(74, 201, 155, 1);
    --tui-status-positive-pale: rgba(74, 201, 155, 0.12);
    --tui-status-positive-pale-hover: rgba(74, 201, 155, 0.24);
    --tui-status-warning: rgba(255, 199, 0, 1);
    --tui-status-warning-pale: rgba(255, 199, 0, 0.12);
    --tui-status-warning-pale-hover: rgba(255, 199, 0, 0.24);
    --tui-status-info: rgba(112, 182, 246, 1);
    --tui-status-info-pale: rgba(112, 182, 246, 0.12);
    --tui-status-info-pale-hover: rgba(112, 182, 246, 0.24);
    --tui-status-neutral: rgb(121, 129, 140);
    // Text
    --tui-text-primary: rgba(27, 31, 59, 1);
    --tui-text-secondary: rgba(27, 31, 59, 0.65);
    --tui-text-tertiary: rgba(27, 31, 59, 0.4);
    --tui-text-primary-on-accent-1: #fff;
    --tui-text-primary-on-accent-2: #fff;
    --tui-text-action: #4c66c3;
    --tui-text-action-hover: #6c86e2;
    --tui-text-positive: #3aa981;
    --tui-text-positive-hover: #7ac5aa;
    --tui-text-negative: #dd4c1e;
    --tui-text-negative-hover: #e38163;
    // Charts
    --tui-chart-categorical-00: var(--tui-background-accent-1);
    --tui-chart-categorical-01: #ea97c4;
    --tui-chart-categorical-02: #a0c5df;
    --tui-chart-categorical-03: #fee797;
    --tui-chart-categorical-04: #b0b0b0;
    --tui-chart-categorical-05: #e29398;
    --tui-chart-categorical-06: #b8474e;
    --tui-chart-categorical-07: #fcc068;
    --tui-chart-categorical-08: #ff8a00;
    --tui-chart-categorical-09: #dab3f9;
    --tui-chart-categorical-10: #7b439e;
    --tui-chart-categorical-11: #8dda71;
    --tui-chart-categorical-12: #fcbb14;
    --tui-chart-categorical-13: #a8cef1;
    --tui-chart-categorical-14: #bd65a4;
    --tui-chart-categorical-15: #7fd7cc;
    --tui-chart-categorical-16: #2fad96;
    --tui-chart-categorical-17: #d4aca2;
    --tui-chart-categorical-18: #9d6f64;
    --tui-chart-categorical-19: #d2e9a2;
    --tui-chart-categorical-20: #aadc42;
    --tui-chart-categorical-21: #3682db;
    --tui-chart-categorical-22: #34b41f;
    // Shadows
    --tui-shadow-small: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1);
    --tui-shadow-small-hover: 0 1rem 2.5rem rgba(0, 0, 0, 0.14);
    --tui-shadow-medium: 0 0.375rem 2rem rgba(0, 0, 0, 0.12);
    --tui-shadow-medium-hover: 0 1.25rem 4rem rgba(0, 0, 0, 0.18);
    --tui-shadow-popup: 0 1.25rem 3rem rgba(0, 0, 0, 0.2);
}

@media screen {
    [tuiTheme='dark'] {
        color-scheme: dark;

        .dark();
    }

    [tuiTheme='light'] {
        color-scheme: light;
    }

    &:root,
    [tuiTheme='light'] {
        .light();
    }
}

@media print {
    &:root,
    [tuiTheme] {
        color-scheme: light;

        .light();
    }
}
