@import '@taiga-ui/core/styles/taiga-ui-local.less';

input[tuiSwitch] {
    .transition(~'color, opacity');

    block-size: 1.25rem;
    inline-size: 2.5rem;
    color: var(--tui-border-hover) !important;
    background: transparent !important;
    overflow: visible;

    &::before {
        content: '';
        display: block;
        block-size: 100%;
        inline-size: 100%;
        border: 0.1875rem solid transparent;
        box-sizing: border-box;
        border-radius: 2rem;
        background: currentColor;
        background-clip: content-box;
        opacity: 0.5;
        transform: none;
        mask: none;
    }

    &::after {
        .transition(transform);

        content: '';
        position: absolute;
        top: 0;
        left: 0;
        inline-size: 1.25rem;
        block-size: 1.25rem;
        border-radius: 100%;
        background: #f6f6f6;
        transform: none;
        outline: none;
        box-shadow:
            0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.24),
            0 0 0.0625rem rgba(0, 0, 0, 0.12),
            inset 0 0.0625rem rgba(255, 255, 255, 0.12) !important;
    }

    &:checked {
        color: var(--tui-background-accent-2) !important;
        background: transparent;

        &::after {
            background: currentColor;
            transform: translateX(1.25rem);
        }
    }

    &:invalid {
        color: var(--tui-status-negative) !important;

        &:not(:checked) {
            background: transparent;
            opacity: 0.4;
        }

        &::after {
            background: var(--tui-status-negative);
        }
    }
}
