input[tuiCheckbox] {
    --t-size: 1.375rem;

    color: var(--tui-text-primary-on-accent-2) !important;
    border-radius: 100%;
    outline: 0.125rem solid var(--tui-background-neutral-2);
    outline-offset: -0.125rem;
    transition: none;
    box-shadow: none !important;
    background: var(--t-background) !important;

    &:checked,
    &:indeterminate {
        --t-background: var(--tui-background-accent-2);

        outline: none;

        &:invalid {
            --t-background: var(--tui-status-negative);
        }

        &:disabled:not(._readonly) {
            --t-background: var(--tui-background-neutral-2);
        }

        &::before {
            clip-path: inset(0);
            transition-delay: 0s;
        }
    }

    &::before {
        mask-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 13L1 8.19231L2.4 6.84615L6 10.3077L13.6 3L15 4.34615L6 13Z" fill="black"/></svg>');
        transition:
            clip-path var(--tui-duration) ease-in-out,
            mask 0s var(--tui-duration) ease-in-out;
        clip-path: inset(0 100% 0 0);
    }

    &:indeterminate::before {
        mask-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2" y="7" width="12" height="2" fill="black" /></svg>');
    }

    &:invalid {
        outline-color: var(--tui-status-negative-pale-hover);
    }

    &[data-size='s']::before {
        inline-size: 1rem;
        block-size: 1rem;
    }
}
