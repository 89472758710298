tui-chip,
[tuiChip] {
    border-radius: 2rem;

    &[data-size='s'] {
        --t-size: 1.875rem;
        --t-padding: 0.6875rem;
    }

    &[data-size='m'] {
        --t-size: 2rem;
        --t-padding: 0.875rem;
    }
}
