tui-app-bar {
    font: var(--tui-font-heading-6);
    block-size: 3.5rem;

    > .t-content {
        position: static;
        padding: 0 0 0 1rem;
        text-align: start;
        transform: none;
        max-inline-size: calc(100% - var(--t-sides, 0px) / 2);

        > * {
            max-inline-size: calc(100% - var(--t-sides, 0px));
        }
    }

    > :last-child [tuiIconButton] {
        margin: 0 -0.25rem;
    }
}
