@import '@taiga-ui/core/styles/taiga-ui-local.less';

input[tuiRadio] {
    --t-size: 1.125rem;

    .transition(color);

    color: var(--tui-background-neutral-2);
    outline: 0.125rem solid;
    outline-offset: -0.125rem;
    box-shadow: none !important;
    background: transparent !important;

    &:disabled:not(._readonly) {
        color: var(--tui-background-neutral-2);
    }

    &:checked {
        color: var(--tui-background-accent-2);

        &::after {
            transform: scale(0.555);
        }
    }

    &:invalid {
        color: var(--tui-status-negative-pale-hover);

        &:checked {
            color: var(--tui-status-negative);
        }
    }
}
